<template>
  <div class="bk-page" id="home">
    <div class="main">
      <div class="main-title" style="margin-bottom:30px;">欢迎使用实验室信息管理系统</div>
      <div>系统故障联系方式</div>
      <div>广州贝壳信息科技有限公司</div>
      <div>020-28185661</div>
      <div style="margin-top:20px;">黄经理：13580451005</div>
      <div>石工：18820142036</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  activated: function () {},
  methods: {},
};
</script>
<style lang="less">
#home {
  display: flex;
  justify-content: center;
  align-items: center;
  .main {
    font-weight: bold;
    text-align: center;
    color: #999;
  }
  .main-title {
    font-size: 20px;
    color: #2db7f5;
  }
}
</style>